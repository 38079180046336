import React from "react";
import { BasicLayout } from "../../layouts/BasicLayout";
import { AppButton, AppLogo, AppText, AppTitle, FlexDiv } from "../../components/ui";
import styled from "styled-components";
import { mobileSize } from "../../util/variables";
import { useStaticQuery, graphql, navigate } from "gatsby";
import Img from "gatsby-image";
import { next_page_view } from "../../services/mixpanel/gift-flow-events";
import { HowItWorks } from "../../components/gift/HowItWorks";

const Gift = () => {
  const handleGiftLinkClick = () => {
    next_page_view("GiftStart");
    navigate("/gift/start");
  };

  const imageData = useStaticQuery(graphql`
    query giftImages {
      hero: file(relativePath: { eq: "gift/gift-shell.webp" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <BasicLayout>
      <Container>
        <LogoContainer>
          <AppLogo />
        </LogoContainer>
        <Hero>
          <HeroImgContainer>
            <Img fluid={imageData.hero.childImageSharp.fluid} />
          </HeroImgContainer>
          <FlexDiv direction="column" align="center">
            <AppTitle textAlign="center" style={{ color: "#10130C" }}>
              Give the Gift of Learning
            </AppTitle>
            <AppText fontSize={24} textAlign="center" style={{ color: "#10130C" }}>
              Share a year of Imprint with someone special.
            </AppText>
            <AppButton style={{ color: "white", fontWeight: 500 }} onClick={handleGiftLinkClick}>
              Gift Imprint
            </AppButton>
          </FlexDiv>
        </Hero>
        <HowItWorks />
      </Container>
    </BasicLayout>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 100px;
  top: 80px;
  display: block;
  @media ${mobileSize} {
    position: static;
    margin: 64px 0 32px 0;
    height: 20px;
    width: 80px;
  }
`;

const Hero = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  min-height: 80vh;
  align-items: center;
  justify-content: space-around;
  gap: 64px;
  padding: 64px;
  @media ${mobileSize} {
    min-height: 0;
    flex-direction: column;
    justify-content: center;
    padding: 0 24px 24px 24px;
    gap: 32px;
  }
`;

const HeroImgContainer = styled.div`
  width: 100%;
  max-width: 428px;
  min-width: 216px;
  @media ${mobileSize} {
    max-width: 205px;
  }
`;

export default Gift;
